@font-face {
    font-family: "$robo-bold";
    src: url(../fonts/RobotoSlab-Bold.ttf);
}
@font-face {
    font-family: "$robo-semiBold";
    src: url(../fonts/RobotoSlab-SemiBold.ttf);
}
@font-face {
    font-family: "$robo";
    src: url(../fonts/RobotoSlab-Regular.ttf);
}
@font-face {
    font-family: "$barlow-bold";
    src: url(../fonts/Barlow-Bold.ttf);
}
@font-face {
    font-family: "$barlow-semiBold";
    src: url(../fonts/Barlow-SemiBold.ttf);
}
@font-face {
    font-family: "$barlow";
    src: url(../fonts/Barlow-Regular.ttf);
}
@font-face {
    font-family: "$helvetica-nue";
    src: url(../fonts/Helvetica-Neue.ttf);
}
@font-face {
    font-family: "$helvetica-nue-light";
    src: url(../fonts/Helvetica-Neue-UltraLight.ttf);
}
