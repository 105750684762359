:root {
  --primary-color: #ff9727;
  --primary-danger: #ff2525;
  --primary-light-color: #faf8f6;
  --primary-light-color-v2: #f8763a;
  --primary-black: #333333;
  --primary-black-v2: #555555;
  --primary-black-v3: #33333314;
  --primary-black-v4: #33333312;
  --primary-black-v5: #909090;
  --primary-black-v6: #f3f3f3;
  --primary-white: #ffffff;
  --primary-danger-v2: #f6631e;
  --barlow: "Barlow";
  --barlow-semi-bold: "$barlow-semiBold";
  --barlow-bold: "$barlow-bold";
  --barlow-helvetica-nue: "Barlow", "Helvetica Nue";
  --light-border-color: #f4f4f4;
  --chat-right-color: #ffeddcf7;
  --chat-left-color: #faf8f6;
  --primary-bg-v1: #f6f6f6;
  --primary-bg-v2: #e6e6e6;
  --primary-border-red-color: #f6631e80;
  --primar-follow-btn: #ff833b;
  --primary-download-btn: #ff864e;
  --primary-download-hover-btn: #f45f1a;
  --primary-founder-heading: #505050;
  --primary-full-black: #000;
  --primary-percentage-box: #11c983;
  --primary-percentage-bg: #e4f3e8;
  --primary-percentage-text: #ff3939;
  --primary-events: #ebe9e9;
  --primary-green: #008000;
  --primary-year-heading: #797979;
  --primar-notify-bg: #ededed;
  --primary-letter-bg: #fc6d2af2;
  --primary-notify-text: #a47979;
  --primary-notify-b: #333333e6;
  --primary-dropdown: #8888;
  --primary-select: #ff9d68;
  --primary-detail-head: #3333338c;
  --light-gray: #ccc;
  --primary-circle-bg: #fa9b42;
  --primary-intel-bg: #fec9a1;
  --primary-dash-bg: #fff0e9c2;
  --primary-view-btn: #ff702d;
  --primary-view-hover: #fb6824;
  --primary-color-textarea: #5c5c5c;
  --primary-addIcon-btn: #fae6dc;
  --primary-addIcon-hover: #fedbc9;
  --primary-add-btn-bg: #fcddce57;
  --primary-add-btn-hover: #d2b3a357;
  --primary-plus-bg: #ff622d1f;
  --primary-branch-bg: #acacac33;
  --primary-so-btn: #353434;
  --primary-so-btn-hover: #262626;
  --primary-et-popover: #000000b8;
  --primary-threat-text: #f30;
  --primary-date: #555;
  --primary-date-bg: #ff8d57;
  --primary-more-info: #fffffccc;
  --primary-more-bg: #ff3527;
  --primary-right-box: #f1f1f1;
  --primary-switch: #888888;
  --negative-bg: #ffe5e5;
  --primary-chart-color: #ffc337;
  --primary-donut-bg: #fbf0eb;
  --primary-donut-heading: #9e9e9ea6;
  --primary-donut-maxText: #39e2f5;
  --primary-donut-minText: #ff833b;
  --primary-notification-bg: #323131;
  --primary-change-pass: #8d8d8d;
  --primary-related-intel: #fff0e9;
  --primary-subBranch:#eeeeee;
  --primary-dropColor:#c4c4c4;
  --primary-table-hover: #f0eeec;
  --font-awesome: 'Font Awesome 5 Free'
}
