.page-title {
  font-size: 26px;
  color: var(--primary-black);
  font-family: "$robo-semibold" !important;
}
.page-container {
  background-color: var(--primary-white);
  box-shadow: 0px 5px 8px 2px rgba(0, 0, 0, 0.04);
  border-radius: 5px;
}
.page-container[data-toggle="border"] {
  border-top: 3px solid var(--primary-color);
}
.search-bar-container {
  position: absolute;
  max-width: 100%;
  padding-left: 10px;
}
tr .table-head {
  position: relative;
  font-size: 1rem !important;
  align-items: center;
  cursor: pointer;
  padding: 10px auto;
  padding-left: 15px !important;
  padding-right: 60px !important;
  white-space: nowrap;
}

.table-head[data-toggle="true"]::after {
  opacity: 0.2;
  content: "\f884";
  font-family: var(--font-awesome);
  position: absolute;
  right: 0;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 0;
  top: 50%;
  transform: translate(-50%, -50%);
}
.table-head[data-toggle="false"]::after {
  opacity: 0.2;
  content: "\f160";
  font-family: var(--font-awesome);
  position: absolute;
  right: 0;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 0;
  top: 50%;
  transform: translate(-50%, -50%);
}
tr .table-data {
  border: none !important;
  padding: 10px 15px !important;
  white-space: nowrap;
  font-size: 1rem !important;
  font-family: var(--barlow) !important;
  // font-weight: ;
}

.min-container {
  min-height: 30vh;
}

.padding-20 {
  padding: 20px !important;
}
.padding-l-20 {
  padding-left: 20px !important;
}
.padding-r-20 {
  padding-right: 20px !important;
}
.padding-x-20 {
  padding-right: 20px !important;
  padding-left: 20px !important;
}
.padding-t-20 {
  padding-top: 20px !important;
}
.padding-b-20 {
  padding-bottom: 20px !important;
}

.padding-b-10 {
  padding-bottom: 10px !important;
}
.padding-y-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
