.ms-team-container{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;

    .header-logo{
        margin: 10px auto;
    }

    .oauth-result{
        width: 30%; 
        color: #FF833B;
        height: auto;
        margin: 0 auto;
        background-color: #faf8f6 !important;
        border-radius: 20px;
        margin-top: 10%;
        padding: 20px;
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        overflow: hidden;
        white-space: break-spaces;
        p{
            width: 100%;
            margin: 0;
            padding: 0;
        }
    }
}


@media (max-width: 768px) {
    .ms-team-container {
      .oauth-result {
        width: auto;
        margin: 10px;
      }
    }
  }